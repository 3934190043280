import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';
import Slider from 'rc-slider';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      spe: 50,
      hue: 0,
      sat: 100,
      lig: 50,
      alp: 100
    }

    this.copy = 2024;
    this.today = new Date();
    this.interval = '';
    
    this.setHue = this.setHue.bind(this);
    this.setInterval = this.setInterval.bind(this);
    this.changeSlider = this.changeSlider.bind(this);
  }

  componentDidMount(){
    this.setInterval();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.spe !== prevState.spe){
      this.setInterval();
    }
  }

  componentWillUnmount(){
    clearInterval(this.interval);
  }
  
  setHue(){
    let hueNow = this.state.hue;

    hueNow++;

    if(hueNow < 361){
      hueNow++;
    }else{
      hueNow = 0;
    }

    this.setState({ hue: hueNow })
  }

  setInterval(){
    clearInterval(this.interval);

    if(this.state.spe){
      this.interval = setInterval(() => this.setHue(), 100 * ((100 - this.state.spe) / 100));
    }
  }

  changeSlider(value, name){
    this.setState({ [name]: value })
  }
  
  render(){
    const alpRender = this.state.alp / 100;

    return (
      <>
        <Helmet>
          <title>ScreenColor {`H:${this.state.hue} S:${this.state.sat}% L:${this.state.lig}% A:${alpRender}`}</title>
        </Helmet>

        <header className={this.state.lig > 50 ? 'dark' : ''}>
          <a href="/">
            <Logo />
          </a>
        </header>
        
        <div className="con-bg">
          <div className="con-color" style={{backgroundColor: `hsla(${this.state.hue}, ${this.state.sat}%, ${this.state.lig}%, ${alpRender})`}}></div>
        </div>
        
        <ul className="set">
          <li>
            <ul>
              <li>Speed</li>
              <li>{this.state.spe}</li>
            </ul>
            <Slider min={0} max={100} value={this.state.spe} onChange={(value) => {this.changeSlider(value, 'spe')}} />
          </li>
          <li>
            <ul>
              <li>Hue</li>
              <li>{this.state.hue}</li>
            </ul>
            <Slider min={0} max={360} value={this.state.hue} onChange={(value) => {this.changeSlider(value, 'hue')}} className="trans hue" />
          </li>
          <li>
            <ul>
              <li>Saturation</li>
              <li>{this.state.sat}%</li>
            </ul>
            <Slider min={0} max={100} value={this.state.sat} onChange={(value) => {this.changeSlider(value, 'sat')}} className="trans" railStyle={{background: `linear-gradient(to right,  hsla(${this.state.hue}, 0%, ${this.state.lig}%, ${alpRender}) 0%, hsla(${this.state.hue}, 100%, ${this.state.lig}%, ${alpRender}) 100%)`}} />
          </li>
          <li>
            <ul>
              <li>Lightness</li>
              <li>{this.state.lig}%</li>
            </ul>
            <Slider min={0} max={100} value={this.state.lig} onChange={(value) => {this.changeSlider(value, 'lig')}} className="trans" railStyle={{background: `linear-gradient(to right,  hsla(${this.state.hue}, ${this.state.sat}%, 0%, ${alpRender}) 0%, hsla(${this.state.hue}, ${this.state.sat}%, 100%, ${alpRender}) 100%)`}} />
          </li>
          <li>
            <ul>
              <li>Alpha</li>
              <li>{alpRender}</li>
            </ul>
            <Slider min={0} max={100} value={this.state.alp} onChange={(value) => {this.changeSlider(value, 'alp')}} className="trans alp" railStyle={{background: `linear-gradient(to right,  hsla(${this.state.hue}, ${this.state.sat}%, ${this.state.lig}%, 0) 0%, hsla(${this.state.hue}, ${this.state.sat}%, ${this.state.lig}%, 1) 100%)`}} />
          </li>
        </ul>

        <footer className={this.state.lig > 50 ? 'dark' : ''}>
          {/* Copyrights &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} <a href="/">ScreenColor</a> by <a href="nabilamerthabit.com" target="_blank" >Nabil Amer Thabit</a>. All Rights Reserved. */}
          <a href="/">ScreenColor</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
        </footer>
      </>
    );
  }
}

export default App;